import aug from './escudos/aug.png'
import bay from './escudos/bay.png'
import boc from './escudos/boc.png'
import bor from './escudos/bor.png'
import col from './escudos/col.png'
import dar from './escudos/dar.png'
import ein from './escudos/ein.png'
import fre from './escudos/fre.png'
import hei from './escudos/hei.png'
import hof from './escudos/hof.png'
import lei from './escudos/lei.png'
import lev from './escudos/lev.png'
import mai from './escudos/mai.png'
import mon2 from './escudos/mon.png'
import stu from './escudos/stu.png'
import uni from './escudos/uni.png'
import wer from './escudos/wer.png'
import wol2 from './escudos/wol.png'
import hol from './escudos/hol.png'
import stp from './escudos/stp.png'
const contextAle1={
    listaNomes:['Augsburg','Bayern','Bochum','Borussia','Holstein Kiel','St. Pauli','Eintracht','Freiburg','Heidenheim','Hoffenheim','Leipzig','Leverkusen','Mainz','Mönchengladbach','Stuttgart','Union Berlin','Werder Bremen','Wolfsburg'],
    listaTimes:['aug','bay','boc','bor','hol','stp','ein','fre','hei','hof','lei','lev','mai','mon','stu','uni','wer','wol'],
    listaEscudos:[aug,bay,boc,bor,hol,stp,ein,fre,hei,hof,lei,lev,mai,mon2,stu,uni,wer,wol2],
    path:'ale1'
  }
  const contextAle1antigo={
    listaNomes:['Augsburg','Bayern','Bochum','Borussia','Colônia','Darmstadt','Eintracht','Freiburg','Heidenheim','Hoffenheim','Leipzig','Leverkusen','Mainz','Mönchengladbach','Stuttgart','Union Berlin','Werder Bremen','Wolfsburg'],
    listaTimes:['aug','bay','boc','bor','col','dar','ein','fre','hei','hof','lei','lev','mai','mon','stu','uni','wer','wol'],
    listaEscudos:[aug,bay,boc,bor,col,dar,ein,fre,hei,hof,lei,lev,mai,mon2,stu,uni,wer,wol2],
    path:'ale1'
  }
export default contextAle1