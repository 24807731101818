import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import React from 'react';
import DarkContext from "../gerais/DarkContext";
import ListaTime from "./ListaTime";
import Loader from "./Loader";
import { useNavigate, useParams } from "react-router-dom";
import { getGuru } from "../api";
import Escudo from "./Escudo";
import Analise from "../pag_1/Analise";
import { ligas } from "../ligas/contexts";
import Topo from "./Topo";

export default function JogoFuturo({principal}){
    const {setData,showingOdds,setShowingOdds,setBandeira,bandeira,a_confrontos,a_analise}=useContext(DarkContext)
    const navigate=useNavigate()
    const nome=principal?a_confrontos:a_analise
    useEffect(()=>{if(!nome)navigate('/')},[])
    const lis=nome?nome.split('-'):['','','']
    const [camp,mandante,visitante]=lis
    const paths=ligas.paths
    console.log(paths,bandeira)
    const context=ligas.contexts[paths.indexOf(bandeira)]
    const {listaNomes,listaTimes}=context
    const [comOdds,setComOdds]=useState(false)
    const [lista,setLista]=useState(false)
    function buscarGuru(){
        if(!a_confrontos&&!a_analise)return navigate('/')
        setBandeira(camp)
        if(!principal)return
        const promise=getGuru(camp,mandante,visitante)
        promise.then(res=>{
            const {resp,data}=res.data
            setLista(resp)
           setData(data)
            return
            let tem=false
            for(let k=0;k<resp.length&&!tem;k++){
                if(resp[k].length==3)tem=true
            }
            if(tem)setComOdds(true)
            
        })
    }
    
    useEffect(buscarGuru,[principal])
    const umTime=mandante==visitante
    return(
         <Tudo margem={!principal}>
                <Topo camp={camp}/>
                    {false&&principal&&comOdds?<Ocultar onClick={()=>setShowingOdds(!showingOdds)}>
                        {showingOdds?'Ocultar odds':'Ver odds'}
                    </Ocultar>:<></>}
                {umTime?
                <span>
                <Escudo camp={bandeira} time={mandante}/>
                </span>
                :
                <span>
                    <Escudo camp={bandeira} time={mandante}/>
                    <ion-icon name="close-outline"></ion-icon>
                    <Escudo camp={bandeira} time={visitante}/>
                </span>}
                {umTime?
                <article>
                <h3>{listaNomes[listaTimes.indexOf(mandante)]} </h3>
                </article>
                :
                <article>
                    <h2>{listaNomes[listaTimes.indexOf(mandante)]} </h2>
                    <h2>{listaNomes[listaTimes.indexOf(visitante)]} </h2>
                </article>}
                {principal?(lista?<ListaTime nome={nome} jogo={{camp,mandante,visitante}} lista={lista} onFav={true} />:<Loader/>):
                <Analise jogo={{camp:bandeira,mandante,visitante}}/>}
        </Tudo>
    )
}
const Ocultar=styled.div`
cursor:pointer;
font-size:16px;
width:120px;
height:40px;
color:#6193dd;
  position:absolute;
  top:50px;
  left:calc(50% - 60px);
  justify-content:space-evenly;

`

const Tudo=styled.div`
width:100%;
height:100%;
flex-direction:column;
justify-content:space-evenly;
position:relative;
box-sizing:border-box;
justify-content:center;
background-color:white;
main{
    justify-content:space-between;
    width:94%;height:0px;display:flex;
    align-items:center;margin:0px 0 20px 0;
}
article{
    margin:${props=>props.margem?'10px 0 30px 0':'10px 0 10px 0'};
    display:flex;
    justify-content:space-between;
    width:92%;
    height:20px;
    
    h3{width:100%;color:#545454;}
    h2{
        margin:0 calc(18% - 20px) 0 calc(18% - 20px);
        width:140px;color:#545454;
    }
}
span{color:#545454;
    width:88%;justify-content:space-evenly;
    display:flex;align-items:center;
    img{height:60px;margin:20px 15px 0 15px;
        cursor:pointer;}
    ion-icon{font-size:25px;margin:55px -3px 0 -3px}
}

`

