import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import DarkContext from "../gerais/DarkContext"
import Apostinha from '../pag_1/Apostinha'
const tabelaList=['%','',"'","'",'%','','%','%']
export default function ListaTime({time,lista,jogo,nome}){
  const {setAnalise,setEscoAnalise,setNumeroGols,setNumeroEsc,keys,setKeys,
    setCampo,setHandicap,setEstadia,setMetade,setToggle,setPagina,
    showingOdds,seta_analise,seta_tabela,blur,setBlur
  }=useContext(DarkContext)
  const path=jogo?.camp
  const navigate=useNavigate()
  function verTabela(jogo){
    setBlur(false)
    const {c,asc,grandeza,estadia,metade,handicap}=jogo
    setCampo(`c${c}`)
    setToggle(asc?false:true)
    if(estadia!==null)setEstadia(estadia)
    if(metade!==null)setMetade(metade)
    if(handicap!==null)setHandicap(handicap)
    setPagina(3)
  seta_tabela(grandeza)
    navigate(`/tabelas`)
  } 
  function verAnalise(est,nome,quantidade=false){
    setBlur(false)
    setAnalise({
      c:est.c,
      grandeza:est.grandeza,
      asc:est.asc,
      metade:est.metade
    })
    if(quantidade!==false){
      if(est.grandeza==2){
        setNumeroGols(quantidade)
      }else{
        setNumeroEsc(quantidade)
      }
    }
    seta_analise(`${nome}`)
    navigate(`/analise`)
    setEscoAnalise(false)
  }
  useEffect(()=>{
    //if(jogo&&nome&&lista)document.getElementById(`estConf${keys.estConf}`).scrollIntoView()
  },[])
  useEffect(()=>{
    const item=keys.time
    //if(false&&time&&item[0]==time&&item[1]==1)document.getElementById(`time${item[2]}`).scrollIntoView()
  },[])
  return(
      <Tudo confronto={jogo}>
        {lista?
          path?
          lista.map((jog,index)=>{
            const {bom,grandeza,valor,c,descricao,pos}=jog[0]
            const {bom:bo,valor:valo,descricao:desc,pos:po}=jog[1]
            const analisavel=(grandeza==1&&!jog[0].asc)||grandeza==2||grandeza==6
            const aposta=jog[2]
            
            return(
              <Holder id={`estConf${index}`} apostavel={aposta&&showingOdds} analisavel={analisavel&&(!aposta||!showingOdds)}>
                {!(blur[0]==1&&blur[1]==index)?<Situacao onClick={()=>{setBlur([1,index]);setKeys({...keys,estConf:index})}} color={bom?'var(--vitoria)':bom===false?'var(--derrota)':'var(--empate)'} >
                  <h3>{pos}º</h3>
                  <p>{descricao}</p>
                  <h4>{valor.toFixed(grandeza==2?2:grandeza==6?1:0)}{tabelaList[grandeza-1]}</h4>
                </Situacao>:<OptionS>
                <button onClick={()=>verAnalise(jog[0],nome)}>
                    Analisar
                  </button>
                  <button onClick={()=>{verTabela(jog[0])}}>
                    Ver tabela
                  </button>
                </OptionS>}
                {!(blur[0]==2&&blur[1]==index)?<Situacao onClick={()=>{setBlur([2,index]);setKeys({...keys,estConf:index})}} color={bo?'var(--vitoria)':bo===false?'var(--derrota)':'var(--empate)'} >
                  <h1>{po}º</h1>
                  <p>{desc}</p>
                  <h2>{valo.toFixed(grandeza==2?2:grandeza==6?1:0)}{tabelaList[grandeza-1]}</h2>
                </Situacao>:<OptionS>
                  <button onClick={()=>verAnalise(jog[0],nome)}>
                    Analisar
                  </button>
                  <button onClick={()=>{verTabela(jog[1])}}>
                    Ver tabela
                  </button>
                </OptionS>}
                {aposta&&showingOdds&&(aposta.info[0]==2||aposta.info[0]==6)&&aposta.odd.length>1?<Texto>
                  {aposta.texto}
                </Texto>:<></>}
                {aposta&&showingOdds?
                  aposta.info[0]==2||aposta.info[0]==6?
                    aposta.odd.length>1?
                      aposta.odd.map(ap=>(
                        <Apostinha reduzida={{
                          tex:ap.t,
                          ode:ap.o,
                          nome:`${nome.replace('-','')}${aposta.info}${ap.q}`,
                          hc:()=>{verAnalise(jog[0],nome,ap.q);setKeys({...keys,estConf:index})}
                        }} />
                      ))
                      :
                  <Apostinha reduzida={{
                    tex:aposta.texto.replace('X',aposta.odd[0].q),
                    ode:aposta.odd[0].o,
                    nome:`${nome.replace('-','')}${aposta.info}${aposta.odd[0].q}`,
                    hc:()=>{verAnalise(jog[0],nome,aposta.odd[0].q);setKeys({...keys,estConf:index})},
                    folga:true
                  }} />
                  :
                  <Apostinha reduzida={{
                    tex:aposta.texto,
                    ode:aposta.odd,
                    nome:`${nome.replace('-','')}${aposta.info}`,
                    hc:()=>{verAnalise(jog[0],nome);setKeys({...keys,estConf:index})},
                    folga:true
                  }} />
                :<></>}
              </Holder>
          )})
          :
          lista.map((jog,index)=>{
            const {bom,grandeza,valor,c,descricao,pos}=jog
            return(
              !(blur[0]==3&&blur[1]==index)?
            <SituacaoSimples id={`time${index}`} onClick={()=>{setBlur([3,index]);setKeys({...keys,time:[time,1,index]})}} color={bom?'var(--vitoria)':bom===false?'var(--derrota)':'var(--empate)'} >
              <h1>{pos}º</h1>
              <p>{descricao}</p>
              <h2>{valor.toFixed(grandeza==2?2:grandeza==6?1:0)}{tabelaList[grandeza-1]}</h2>
            </SituacaoSimples>:
            <OptionSs>
                  <button onClick={()=>{verAnalise(jog,nome,grandeza==2?1:7)}}>
                    Analisar
                  </button>
                  <button onClick={()=>{verTabela(jog)}}>
                    Ver tabela
                  </button>
                </OptionSs>
          )})
        :<></>}
        <MargemBaixo/>
      </Tudo>
    )
}

const MargemBaixo=styled.div`
min-height:40px;width:50px;background-color:;
`
const Texto=styled.div`
height:20px;
margin-top:10px;
font-size:13px;
`
const Aposta=styled.div`
cursor:pointer;
position:relative;
box-sizing:border-box;
padding:0 15px 0 35px;
justify-content:space-between;
background-color:#065fe5;
height:40px;width:90%;
margin-top:${props=>props.folga?'15px':'5px'};
margin-left:15px;
border-top-right-radius:20px;
border-bottom-right-radius:20px;
color:white;
h6{font-weight:700;font-size:18px}
h5{font-size:13px;}
`

const Holder=styled.div`background-color:${props=>props.apostavel?'#96c2ff':''};
flex-wrap:wrap;position:relative;
width:98%;
border-radius:12px;
padding:${props=>props.apostavel?'5px 0 5px 0':''};
margin:${props=>props.analisavel?'0px 0 15px 12px':'0px 0 15px 12px'};
justify-content:space-around;
`
const Tudo=styled.div`
margin-top:${props=>props.confronto?'10px':''};
padding:5px;
border-radius:5px;
    width:98%;height:100%;
    box-sizing:border-box;
    flex-direction:column;overflow:hidden;
    overflow-y:scroll;
    ::-webkit-scrollbar {
        width:12px
      }
      ::-webkit-scrollbar-track {
        border-top-right-radius:5px;
        border-bottom-right-radius:5px;
      }
      ::-webkit-scrollbar-thumb {
        background: #e5e5e5; 
        border-radius:5px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #cecece; 
      }
`
const Situacao=styled.button`position:relative;
height:105px;
width:calc(50% - 8px);background-color:${props=>props.color};
justify-content:space-evenly;
border-radius:12px;
h1{font-weight:700;font-size:18px;position:absolute;top:5px;left:5px;}
h2{font-weight:600;font-size:16px;position:absolute;bottom:5px;right:5px;}
h3{font-weight:700;font-size:18px;position:absolute;top:5px;right:5px;}
h4{font-weight:700;font-size:16px;position:absolute;bottom:5px;left:5px;}
p{width:95%;font-size:13px;line-height:15px;}
article{width:70%;}
`
const OptionS=styled.div`position:relative;
height:105px;z-index:30;flex-direction:column;
width:calc(50% - 8px);background-color:transparent;
justify-content:space-evenly;
border-radius:12px;
button{
display:flex;justify-content:center;background-color:white;
border:0;width:96%;height:44%;margin:0;border-radius:10px;
}
`
const OptionSs=styled.div`
margin:0px 0 8px 12px;
min-height:72px;z-index:30;
width:94%;background-color:transparent;
justify-content:space-evenly;
border-radius:15px;
button{
display:flex;justify-content:center;background-color:white;
border:0;width:48%;height:90%;margin:0;border-radius:10px;
}
`
const SituacaoSimples=styled.button`
margin:0px 0 8px 12px;
min-height:72px;
width:94%;background-color:${props=>props.color};
justify-content:space-evenly;
border-radius:15px;
h1{font-weight:700;font-size:18px;}
h2{font-weight:600;font-size:16px;}
p{width:65%;font-size:14px}
article{width:70%;}
`