import bre from './escudos/bre.png'
import cle from './escudos/cle.png'
import hav from './escudos/hav.png'
import len from './escudos/len.png'
import lor from './escudos/lor.png'
import los from './escudos/los.png'
import lyo from './escudos/lyo.png'
import met from './escudos/met.png'
import mnc from './escudos/mnc.png'
import mpl from './escudos/mpl.png'
import nan from './escudos/nan.png'
import nic from './escudos/nic.png'
import oly from './escudos/oly.png'
import psg from './escudos/psg.png'
import rei from './escudos/rei.png'
import ren from './escudos/ren.png'
import str from './escudos/str.png'
import tou from './escudos/tou.png'
import ang from './escudos/ang.png'
import aux from './escudos/aux.png'
import ste from './escudos/ste.png'
const contextFra1={
  listaNomes:['Brestois','Angers','Auxerre','Le Havre','Lens','Lille','Lyon','Saint-Étienne','Mônaco','Montpellier','Nantes','Nice','Olympique','PSG','Reims','Rennes','Strasbourg','Toulouse'],
  listaTimes:['bre','ang','aux','hav','len','los','lyo','ste','mon','mpl','nan','nic','oly','psg','rei','ren','str','tou'],
  listaEscudos:[bre,ang,aux,hav,len,los,lyo,ste,mnc,mpl,nan,nic,oly,psg,rei,ren,str,tou],
  path:'fra1'
}
const contextFra1antigo={
    listaNomes:['Brestois','Clermont','Le Havre','Lens','Lorient-Bretagne','LOSC','Lyon','Metz','Mônaco','Montpellier','Nantes','Nice','Olympique','PSG','Reims','Rennes','Strasbourg','Toulouse'],
    listaTimes:['bre','cle','hav','len','lor','los','lyo','met','mon','mpl','nan','nic','oly','psg','rei','ren','str','tou'],
    listaEscudos:[bre,cle,hav,len,lor,los,lyo,met,mnc,mpl,nan,nic,oly,psg,rei,ren,str,tou],
    path:'fra1'
  }
export default contextFra1