
import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import React from 'react';
import DarkContext from "../gerais/DarkContext";
import Loader from "../pag_2/Loader";
import { getResultados, ordenarApostas } from "../api";
import { useNavigate } from "react-router-dom";
import { ligas } from "../ligas/contexts";
import Bandeirada from "../pag_2/Bandeirada";
import Tipada from "../pag_2/Tipada";
import ListaApostas from "./ListaApostas";
const minimo=70
const maximo=100
export default function Resultados(){
    const {setResultados,setKeys,keys,ordBet,
        queryResult,setQueryResult,bandeira,bandeirando,setBandeirando
        }=useContext(DarkContext)
    const {paths,imagens}=ligas
    const [resposta,setResposta]=useState(false)
    const {resp,cont}=resposta
    
    const porc=resp?.porc
    const green=resp?.green
    const red=resp?.red
    const lucro=resp?.lucro
    const apostas=resp?.abertas
    
    const [contagem,setContagem]=useState(0)
    
    const [tipando,setTipando]=useState(false)
    function buscar(){
        setContagem(contagem+1)
        setKeys({...keys,result:0})
        setResultados(false)
        setResposta(false)
        const promise=getResultados(queryResult,contagem)
        promise.then(res=>{
            setResposta(res.data)
            setResultados(res.data.resp.antigas)
        })
    }
    const {tipos,ev}=queryResult
    function formarFraseTipos(){
        return<HolderIcones>
            {tipos.includes(1)?<ion-icon name={'calendar-clear'}></ion-icon>:<></>}
            {tipos.includes(1)&&(tipos.includes(2)||tipos.includes(6))?<h6>+</h6>:<></>}
            {tipos.includes(2)?<ion-icon name={'football'}></ion-icon>:<></>}
            {tipos.includes(2)&&tipos.includes(6)?<h6>+</h6>:<></>}
            {tipos.includes(1)?<ion-icon name={'golf'}></ion-icon>:<></>}
        </HolderIcones>
    }
    function formarBands(){
        return<HolderIcones>
            {queryResult.camps.map(c=><img src={imagens[paths.indexOf(c)]}/>)}
            
        </HolderIcones>
    }
    function mudarEv(num){
        const evAtual=queryResult.ev
        const novoEv=evAtual+num
        if(evAtual==minimo &&num<0)return setQueryResult({...queryResult,ev:0})
        if(novoEv>100)return setQueryResult({...queryResult,ev:100})
        if(novoEv<minimo)return setQueryResult({...queryResult,ev:minimo})
        setQueryResult({...queryResult,ev:novoEv})
    }
    const navigate=useNavigate()
    useEffect(buscar,[queryResult,bandeira])
    return(
         <Tudo>
            {tipando?<Tipada fechar={()=>setTipando(false)} />:<></>}
            {bandeirando?<Bandeirada fechar={()=>setBandeirando(false)} />:<></>}
            <Cabecalho>
                <Escolhe>
                    <EscolherTipos onClick={()=>setTipando(true)}>
                        {/*formarFraseTipos()*/}
                        {formarBands()}
                        <aside>ALTERAR</aside>
                    </EscolherTipos>
                    <EscolherEv>
                        <section>
                            <p>E.V. mínimo:</p>
                        </section>
                        <main>
                            <HolderBtnNum>{queryResult.ev>=minimo?<BtnNumero handlePress={()=>{mudarEv(-5)}} name={'caret-back'}/>:<></>}</HolderBtnNum>
                            <p >{queryResult.ev}<small></small></p>
                            <HolderBtnNum>{queryResult.ev<maximo?<BtnNumero handlePress={()=>{mudarEv(5)}} name={'caret-forward'}/>:<></>}</HolderBtnNum>
                        </main>
                    </EscolherEv>
                </Escolhe>
                
                
                {resposta&&cont==contagem-1?
                (red+green==0?
                    <Show><h5>Nenhuma aposta finalizada desse tipo</h5></Show>:
                    <Show onClick={()=>{
                        navigate(`/odds-semanas`)
                        //navigate('/odds-semanas')
                    }} cor={lucro>=0?'2bce56':'f27979'}>
                        <article>
                            {lucro>=0?<h2><small>lucro de </small>{lucro?lucro:0}<small>%</small></h2>:<h3><small>prejuízo de </small>{-lucro}<small>%</small></h3>}
                        </article>
                        <HolderBarrinha>
                            <Barrinha red={red} green={green} porc={porc}></Barrinha>
                            <h1>{green}<small> / </small>{green+red}</h1>
                        </HolderBarrinha>
                    </Show>
                ):<></>}
            </Cabecalho>
            
            
            {resposta&&cont==contagem-1?
                <ListaApostas apostas={ordBet==2?apostas:ordenarApostas(apostas,ordBet)}/>
                :<HolderLoader><Loader/></HolderLoader>}
        </Tudo>
    )
}
const BtnNumero=({name,handlePress})=>{
    return(
        <BotaoNumero  onClick={handlePress}>
            <ion-icon name={name}></ion-icon>
        </BotaoNumero>
    )
}
const HolderIcones=styled.div`
color:#545454;
ion-icon{font-size:14px;}
img{height:28px;}
h6{font-size:20px;font-weight:700;margin:0 5px 0 5px;}
`
const Escolhe=styled.div`
flex-direction:column;
justify-content:space-between;
width:185px;background-color:;
height:130px;
`
const EscolherTipos=styled.div`
aside{color:white;display:flex;
justify-content:center;align-items:Center;
height:18px;font-size:12px;width:75px;
border-top-left-radius:15px;
border-bottom-right-radius:15px;
position:absolute;top:0px;left:0px;
background-color:black;
}
position:relative;flex-direction:column;
cursor:pointer;
background-color:#e8e8e8;
border-radius:15px;
height:70px;padding-top:20px;
width:100%;justify-content:space-evenly;
p{font-size:13px;margin-right:7px;height:30px;background-color:;
strong{font-size:18px;margin:5px 5px 0 0;}
display:flex;align-items:flex-end
}
ion-icon{font-size:28px;}
`

const EscolherEv=styled.div`
height:50px;
width:100%;justify-content:space-between;
section{background-color:;
    width:85px;
    font-size:14px;
}
main{p{width:35px;}background-color:;
    font-size:22px;
    small{font-size:18px;}
    width:calc(100% - 85px);
display:flex;justify-content:;align-items:center;
}
`

const Show=styled.div`position:relative;cursor:pointer;
width:calc(100% - 195px);
border-radius:15px;
max-width:180px;
height:130px;
flex-direction:column;justify-content:space-evenly;
background-color:#${props=>props.cor};
article{
width:90%;display:flex;align-items:center;justify-content:space-evenly;;
}
h5{width:99%;}
h1{
    font-weight:400;font-size:18px;small{font-size:15px}}
h2{color:white;font-weight:700;font-size:30px;
    small{font-size:18px;font-weight:500;margin-right:3px;}}
h3{color:white;font-weight:700;font-size:25px;
    small{font-size:18px;font-weight:500}}
`
const BotaoNumero=styled.button`background-color:transparent;
font-size:28px;color:#545454;
justify-content:center;
height:35px;width:35px;
border-radius:50%
`
const HolderBtnNum=styled.div`
height:35px;width:35px;
`
function Barrinha({porc,green,red,small}){
    return(
        <Bar vazio={green+red==0} small={small} red={100-porc}>
            {porc>0?<Verde width={porc} ></Verde>:<></>}
        </Bar>
    )
}
const Bar=styled.div`
position:relative;
border-radius:20px;
height:${props=>props.small?18:18}px;
width:${props=>props.small?70:85}%;
background-color:var(--${props=>props.vazio?'empate':'derrota'});
border:1px solid #969696;
font-size:${props=>props.small?14:20}px;
p{position:absolute;right:0;width:${props=>props.red}%}
`
const Verde=styled.div`
justify-content:center;
border-radius:25px;
border-right:1px solid #969696;
position:absolute;
top:0;
left:-0.5px;
box-sizing:border-box;
background-color:#72e591;
height:100%;
width:${props=>props.width}%
`

const HolderBarrinha=styled.div`margin-bottom:-5px;
height:50px;flex-direction:column;
width:100%;justify-content:space-evenly;
`
const Cabecalho=styled.div`width:92%;
box-sizing:border-box;background-color:;
height:155px;flex-direction:row;justify-content:space-between;
max-width:400px;
`
const HolderLoader=styled.div`height:calc(100% - 175px)`

const Tudo=styled.div`
box-sizing:border-box;
background-color:white;
max-width:400px;
width:100%;
height:100%;
flex-direction:column;

`
