import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import DarkContext from "../gerais/DarkContext"
import ReactLoading from 'react-loading';
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import Escudo from "./Escudo";
import { getPartidas, getPreFlop } from "../api";
import { fazerDegrade, ligas } from "../ligas/contexts";

export default function ListaPartidas(){
  const {miniEspera,setMiniEspera,setEscoAnalise,setSwit3,selecionado,setSelecionado,
    setNumeroEsc,setNumeroGols,seta_confrontos,seta_partidas,seta_analise,
    setKeys,keys,setMiniAnalise,setAnalise,setBandeira}=useContext(DarkContext)
  const navigate=useNavigate()
  const [partidas,setPartidas]=useState(false)
  const {paths,imagens}=ligas
  
  function buscarPartidas(){
    const promise=getPartidas(paths)
    promise.then(res=>{
      setInterval(() => {
        buscarPartidas()
      }, 5*60*1000);
      setPartidas(res.data)
    })
  }
  useEffect(buscarPartidas,[])
  useEffect(()=>{
    setKeys({...keys,estConf:0})
    if(partidas&&partidas.length>0)document.getElementById(`confronto${keys.confronto}`).scrollIntoView()
  },[partidas])
  return(
      <Tudo >
        {partidas?
        <Quadro id={'quadro'}>
        {partidas.map((jog,index)=>{
          const {mandante,visitante,camp,texto,label,placar}=jog
          const context=label?{}:ligas.contexts[paths.indexOf(camp)]
          const {listaNomes,listaTimes}=context
          return(label?<Label id={`confronto${index}`}><p>{label}</p></Label>:
            <Faltam 
            tamanho={88}
             id={`confronto${index}`}
            >
              <Jogo color={texto==''?'#cccccc':'white'}
               onClick={()=>{
                //document.getElementById(`confronto${index}`).scrollIntoView()
                //document.getElementById(`quadro`).scrollBy(0, -200)
                setKeys({...keys,confronto:index})
                if(placar){
                  seta_partidas(`${camp}-${mandante}-${visitante}`)
                  navigate(`/partidas`)
                }else{
                  seta_confrontos(`${camp}-${mandante}-${visitante}`);navigate(`/confrontos`);setEscoAnalise(true);setSwit3(true);setBandeira(camp)
                } }} 
                >
                <Layer degrade={texto==''?'linear-gradient(to left,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(255, 255, 255, 0.4))':'linear-gradient(to left,white,white,white,white,white,rgba(255, 255, 255, 0.4))'}/>
                <Bandeira><img src={imagens[paths.indexOf(camp)]}/></Bandeira>
                <HolderEscudos>
                    <Escudo camp={camp} time={mandante} noClick={true}/>
                    {placar?<div><h6>{placar[0]}</h6><ion-icon name="close-outline"></ion-icon><h6>{placar[1]}</h6></div>:<ion-icon name="close-outline"></ion-icon>}
                    <Escudo camp={camp} time={visitante} noClick={true}/>
                </HolderEscudos>
                
              </Jogo>
              <span>
                {texto[0]=='C'?<ReactLoading type={'cylon'} color={'white'} height={22} width={32} />:<></>}
              </span>
              <h1>{texto[0]=='C'?texto.split('!')[0]+'!':texto}</h1>
              
          </Faltam>
        )})}
<MargemBaixo/>
        </Quadro>
        :<Loader branca={true} />}
        
      </Tudo>
    )
}

const Bandeira=styled.div`
overflow: hidden;
border-top-left-radius:15px;
border-bottom-left-radius:15px;
height:100%;width:100px;
position:absolute;left:-0px;top:0px;
img{height:125%;
left:-11px;
position: absolute;
    clip: rect(0px, 100px, 100px, 0px);
}
`

const MargemBaixo=styled.div`
min-height:40px;width:50px;background-color:;
`
const Label=styled.div`
p{
  display:flex;align-items:center;justify-content:center;
  font-size:25px;color:white;margin-left:6px;
}
max-width:420px;
width:100%;
min-height:50px;margin:0px 0 10px 12px;
position:sticky;top:0px;z-index:12;
background-color:var(--verde1);
`
const HolderEscudos=styled.div`
z-index:11;background-color:;
width:140px;position:absolute;top:5px;
height:57px;justify-content:space-between;
font-size:20px;
ion-icon{font-size:18px;color:#545454;}
img{
    height:43.4px;width:auto
};
h6{font-size:20px;font-weight:500;}
div{width:40px;justify-content:space-between;background-color:;
ion-icon{margin:0 -5px 0 -5px}
}
`
const HolderNomes=styled.div`
z-index:11;position:absolute;bottom:0px;
justify-content:space-between;
height:30px;color:#545454;
font-size:13px;
h5{margin:0 5px 0 5px;display:flex;justify-content:center;width:88px;height:30px;background-color:;}
`
const Quadro=styled.div`

flex-direction:column;
height:100%;width:98%;
overflow:hidden;
overflow-y:scroll;
  ::-webkit-scrollbar {width:12px}
  ::-webkit-scrollbar-thumb {background: #00702c; border-radius:5px;}
  ::-webkit-scrollbar-thumb:hover {background: #006028;}
`
const Faltam=styled.section`
min-height:${props=>props.tamanho}px;
background-color:#003817;
position:relative;
margin:0px 0 10px 20px;
max-width:400px;
border-radius:15px;
width:94%;
box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
:active{box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.2);};
span{
  position:absolute;top:${props=>props.aberto?'85.2':'70.2'}px;align-items:center;left:30px;
  display:flex;justify-content:space-evenly;
};
h1{display:flex;justify-content:center;width:100%;
  color:white;position:absolute;top:${props=>props.aberto?'88.4':'73.4'}px;
  font-size:13px;
}
`
const Layer=styled.div`
position:absolute;right:0;
width:100%;z-index:10;
height:100%;;
border-radius:15px;
background-image:${props=>props.degrade};

`

const Jogo=styled.button`
width:100%;
height:${props=>props.aumenta?87:72}px;
cursor:pointer;
position:absolute;flex-direction:column;
justify-content:space-evenly;
background-color:${props=>props.color};
border-radius:15px;
h1{font-weight:700;font-size:18px;}
p{
  width:65%;font-size:25px;font-weight:500;height:100%;
display:flex;align-items:center;justify-content:center;
small{font-size:18px;margin:0 5px 0 5px;}
}

`
const Tudo=styled.div`
background-color:var(--verde1);
width:100%;
p{
  height: 60px;
width: 468px};
padding:10px 0 0 0;
box-sizing:border-box;
height:100%;
flex-direction:column;
width:100%;height:100%;
justify-content:center;
section{
  width:94%;display:flex;justify-content:center;
}
`