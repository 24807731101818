
import ars from './escudos/ars.png'
import ast from './escudos/ast.png'
import bou from './escudos/bou.png'
import bre from './escudos/bre.png'
import bri from './escudos/bri.png'
import che from './escudos/che.png'
import cry from './escudos/cry.png'
import eve from './escudos/eve.png'
import ful from './escudos/ful.png'
import liv from './escudos/liv.png'
import cit from './escudos/cit.png'
import man from './escudos/man.png'
import new2 from './escudos/new.png'
import not from './escudos/not.png'
import tot from './escudos/tot.png'
import wes from './escudos/wes.png'
import wol from './escudos/wol.png'
import bur from './escudos/bur.png'
import lut from './escudos/lut.png'
import she from './escudos/she.png'
import ips from './escudos/ips.png'
import sou from './escudos/sou.png'
import lei from './escudos/lei.png'
const contextIng1Antigo={
    listaNomes:['Arsenal','Aston Villa','Bournemouth','Brentford','Brighton','Burnley','Chelsea','Crystal Palace','Everton','Fulham','Liverpool','Luton Town','Manchester City','Manchester United','Newcastle','Nottingham Forest','Sheffield United','Tottenham','West Ham','Wolverhampton'],
    listaTimes:['ars','ast','bou','bre','bri','bur','che','cry','eve','ful','liv','lut','cit','man','new','not','she','tot','wes','wol'],
    listaEscudos:[ars,ast,bou,bre,bri,bur,che,cry,eve,ful,liv,lut,cit,man,new2,not,she,tot,wes,wol],
    path:'ing1'
  }
  const contextIng1={
    listaNomes:['Arsenal','Aston Villa','Bournemouth','Brentford','Brighton','Ipswich Town','Chelsea','Crystal Palace','Everton','Fulham','Liverpool','Leicester','Manchester City','Manchester United','Newcastle','Nottingham Forest','Southampton','Tottenham','West Ham','Wolverhampton'],
    listaTimes:['ars','ast','bou','bre','bri','ips','che','cry','eve','ful','liv','lei','cit','man','new','not','sou','tot','wes','wol'],
    listaEscudos:[ars,ast,bou,bre,bri,ips,che,cry,eve,ful,liv,lei,cit,man,new2,not,sou,tot,wes,wol],
    path:'ing1'
  }
export default contextIng1
