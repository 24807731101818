import alm from './escudos/alm.png'
import atb from './escudos/atb.png'
import atm from './escudos/atm.png'
import bar from './escudos/bar.png'
import bet from './escudos/bet.png'
import cad from './escudos/cad.png'
import cel from './escudos/cel.png'
import get from './escudos/get.png'
import gir from './escudos/gir.png'
import mal from './escudos/mal.png'
import osa from './escudos/osa.png'
import ray from './escudos/ray.png'
import rem from './escudos/rem.png'
import res from './escudos/res.png'
import sev from './escudos/sev.png'
import val from './escudos/val.png'
import vil from './escudos/vil.png'
import ala from './escudos/ala.png'
import gra from './escudos/gra.png'
import las from './escudos/las.png'
const contextEsp1={
    listaNomes:['Alavés','Almeria','Athletico Bilbao','Athletico Madrid','Barcelona','Bétis','Cádiz','Celta de Vigo','Getafe','Girona','Granada','Las Palmas','Mallorca','Osasuna','Rayo Vallecano','Real Madrid','Real Sociedad','Sevilla','Valencia','Villareal'],
    listaTimes:['ala','alm','atb','atm','bar','bet','cad','cel','get','gir','gra','las','mal','osa','ray','rem','res','sev','val','vil'],
    listaEscudos:[ala,alm,atb,atm,bar,bet,cad,cel,get,gir,gra,las,mal,osa,ray,rem,res,sev,val,vil],
    path:'esp1'
}
export default contextEsp1