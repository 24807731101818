import ata from './escudos/ata.png'
import bol from './escudos/bol.png'
import cag from './escudos/cag.png'
import emp from './escudos/emp.png'
import fio from './escudos/fio.png'
import fro from './escudos/fro.png'
import gen from './escudos/gen.png'
import int2 from './escudos/int.png'
import juv from './escudos/juv.png'
import laz from './escudos/laz.png'
import lec from './escudos/lec.png'
import mil from './escudos/mil.png'
import mon from './escudos/mon.png'
import nap from './escudos/nap.png'
import rom from './escudos/rom.png'
import sal from './escudos/sal.png'
import sas from './escudos/sas.png'
import tor from './escudos/tor.png'
import udi from './escudos/udi.png'
import ver from './escudos/ver.png'
const contextIta1={
    listaNomes:['Atalanta','Bologna','Cagliari','Empoli','Fiorentina','Frosinone','Genoa','Inter','Juventus','Lazio','Lecce','Milan','Monza','Napoli','Roma','Salernitana','Sassuolo','Torino','Udinese','Verona'],
    listaTimes:['ata','bol','cag','emp','fio','fro','gen','int','juv','laz','lec','mil','mon','nap','rom','sal','sas','tor','udi','ver'],
    listaEscudos:[ata,bol,cag,emp,fio,fro,gen,int2,juv,laz,lec,mil,mon,nap,rom,sal,sas,tor,udi,ver],
    path:'ita1'
  }
export default contextIta1