import styled from "styled-components"
import DarkContext from "../gerais/DarkContext"
import { useContext } from "react"
import logo from '../gerais/logoBranca.png'
import { useNavigate } from "react-router-dom"
export default function BarraMenu(){
    const {
        setPagina,pagina,
        menuando,setMenuando,
        procurando,setProcurando,
        setApostando,apostando,
        setMiniAnalise,setMiniEspera
       }=useContext(DarkContext)
    return (
        <Layer onClick={()=>setMenuando(false)}>
            <Tudo>
                <Menu>
                    <Opcao 
                    color={'#008037'} 
                    text={false}
                    onClick={()=>{setMenuando(!menuando)}}  >
                    <img src={logo} />
                    </Opcao>  
                </Menu>
                <Caminho setMenuando={setMenuando} texto={'Próximas partidas'} path={''} />
                <Caminho setMenuando={setMenuando} texto={'Estatísticas'} path={'escolher-tabela'} />
                <Titulo>
                    <p>APOSTAS</p>
                </Titulo>
                <Caminho setMenuando={setMenuando} texto={'Destaques'} path={'odds'} />
                <Caminho setMenuando={setMenuando} texto={'Favoritas'} path={'favoritas'} />
                <Caminho setMenuando={setMenuando} texto={'Treinar offline'} path={'multiplas'} />
                {/*<Titulo>
                    <p>MOEDAS FICTÍCIAS</p>
                </Titulo>
                
                <Caminho setMenuando={setMenuando} texto={'Torneios'} path={'torneios'} />*/}
            </Tudo>

        </Layer>
        
    )
}
function Caminho({texto,path,setMenuando}){
    const navigate=useNavigate()
    return(
        <Botao setMenuando={setMenuando} onClick={()=>{navigate(`/${path}`);setMenuando(false)}}>
            <p>{texto}</p>
        </Botao>
    )
}
const Titulo=styled.div`
p{
color:white;font-weight:700;font-size:15px;
};
padding:10px;width:180px;
border-radius:7px;
border-radius:7px;
margin:10px 0 7px 0;
`
const Botao=styled.div`cursor:pointer;
p{
color:white;font-size:18px;
};
padding:10px;width:180px;
background-color:#026d2e;
border-radius:7px;
margin-bottom:10px;
`
const Layer=styled.div`
position:fixed;
;z-index:13;
width:100vw;
height:100vh;
background-color:rgb(0,0,0,0.8);
max-width:400px;
flex-direction:column;
`
const Tudo=styled.div`
background-color:var(--verde1);
width:250px;
padding:0 0 0 0;
box-sizing:border-box;
height:100vh;
flex-direction:column;
position:fixed;left:0;
@media(min-width:400px){
    left:calc(50% - 200px);
} 

`

const Menu=styled.div`
max-width:400px;
width:100%;
height:64px;
margin-bottom:20px;
`
const Opcao=styled.div`cursor:pointer;z-index:3;
padding-bottom:2px;
img{height:38px;width:auto}
    background-color:${props=>props.color};
    position:relative;
    font-size:32px;display:flex;align-items:center;
    justify-content:center;
    border:0;height:62px;width:25vw;max-width:100px;
    color:#545454;
h1{margin-left: auto;margin-right: auto;;width:50px;font-size:16px;
    small{font-size:14px;}
};
aside{display:none}
border-bottom-right-radius:15px;

:hover{
    aside{display:block;
        position:absolute;;border-radius:5px;
        padding:5px;background-color:black;
        font-size:11px;color:white;
        position:absolute;z-index:1;bottom:0;left:center;
    }
}
:active{
    box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.2);
    }
`