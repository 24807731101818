import styled from "styled-components"
import DarkContext from "../gerais/DarkContext"
import { useContext } from "react"
import { useState } from "react"
import { useEffect } from "react"
import { getResultadosSemanas } from "../api"
import Loader from "../pag_2/Loader"
import { useNavigate } from "react-router-dom";
export default function Semanas(){
    const {
        queryResult,bandeira,resultados,setResultados
        }=useContext(DarkContext)
    
    const [semanas,setSemanas]=useState(false)
    const [semanaSelecionada,setSemanaSelecionada]=useState(null)
    function buscar(){
        setSemanas(false)
        const promise=getResultadosSemanas({...queryResult})
        promise.then(res=>{
            setSemanas(res.data)
            console.log(res.data)
        })
    }
    const navigate=useNavigate()
    useEffect(buscar,[])
    useEffect(()=>{
        
    },[])
    return(
        <Tudo>
            <Cabecalho>

            </Cabecalho>
            <Quadro>
                {semanas?
                semanas.map(semana=>{
                    const {red,green,porc,lucro,numero,apostas}=semana
                    return(red+green==0?<Semana cor={'c4c4c4'}>Não existem apostas</Semana>:
                    <Semana 
                    onClick={()=>{setResultados(apostas);navigate(`/odds-semanas-apostas`)}}
                    cor={lucro>=0?'2bce56':'f27979'}>
                        {lucro>=0?<h2><small>lucro de </small>{lucro?lucro:0}<small>%</small></h2>:<h3><small>prejuízo de </small>{-lucro}<small>%</small></h3>}
                        <HolderBarrinha>
                            <Barrinha red={red} green={green} porc={porc}/>
                            <h1>{green}<small> / </small>{green+red}</h1>
                        </HolderBarrinha>
                        <Label>SEMANA {numero}</Label>
                    </Semana>
                )})
                :<Loader/>}<MargemBaixo/>
            </Quadro>
        </Tudo>
    )
}
const HolderBarrinha=styled.div`
flex-direction:column;width:50%;height:45px;justify-content:space-between;
`
const Label=styled.div`
position:absolute;top:5px;font-size:13px;
`
const Semana=styled.div`position:relative;cursor:pointer;
box-sizing:border-box;
padding:0 0 0 15px;
border-radius:15px;
justify-content:space-between;
width:94%;
max-width:400px;min-height:70px;
background-color:#${props=>props.cor};
margin-top:15px;
h1{
    font-weight:400;font-size:18px;small{font-size:15px}}
h2{color:white;font-weight:700;font-size:30px;
    margin-top:10px;
    small{font-size:18px;font-weight:500;margin-right:3px;}}
h3{color:white;font-weight:700;font-size:25px;
    margin-top:10px;
    small{font-size:18px;font-weight:500}}
`
const Cabecalho=styled.div`

`
const Tudo=styled.div`
background-color:white;
height:100%;width:100%;flex-direction:column;
`
const Quadro=styled.div`
height:calc(100% - 0px);
width:100%;
display:flex;
flex-direction:column;
align-items:center;
overflow:hidden;
overflow-y:scroll;
::-webkit-scrollbar {
    width:12px
  }
  ::-webkit-scrollbar-track {
    border-top-right-radius:5px;
    border-bottom-right-radius:5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e5e5e5; 
    border-radius:5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #cecece; 
  }`
function Barrinha({porc,green,red,small}){
    return(
        <Bar vazio={green+red==0} small={small} red={100-porc}>
            {porc>0?<Verde width={porc} ></Verde>:<></>}
        </Bar>
    )
}
const Bar=styled.div`
position:relative;
border-radius:20px;
height:${props=>props.small?18:18}px;
width:${props=>props.small?70:85}%;
background-color:var(--${props=>props.vazio?'empate':'derrota'});
border:1px solid #969696;
font-size:${props=>props.small?14:20}px;
p{position:absolute;right:0;width:${props=>props.red}%}
`
const Verde=styled.div`
justify-content:center;
border-radius:25px;
border-right:1px solid #969696;
position:absolute;
top:0;
left:-0.5px;
box-sizing:border-box;
background-color:#72e591;
height:100%;
width:${props=>props.width}%
`
const MargemBaixo=styled.div`
min-height:40px;width:50px;background-color:;
`