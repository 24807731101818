import { useContext } from 'react'
import styled from 'styled-components'
import DarkContext from '../gerais/DarkContext'
import logo from '../gerais/logoVerde.png'
import { useNavigate } from 'react-router-dom'

export default function Menu(){
    const {
       setPagina,pagina,
       menuando,setMenuando,
       procurando,setProcurando,
       setApostando,apostando,
       setMiniAnalise,setMiniEspera
      }=useContext(DarkContext)
      const pag=pagina
      const navigate=useNavigate()
      function setarPagina(num){
        setPagina(num)
        if(num!=2)setApostando(false)
        if(num!=3)setMenuando(false)
        if(num!=4)setProcurando(false)
        setMiniEspera(false)
        setMiniAnalise(false)
      }
    return(
        <Tudo >
            
            <Opcao 
                color={'transparent'} 
                text={false}
                onClick={()=>{navigate('/')}}  >
                   <ion-icon name="calendar-outline"></ion-icon>
            </Opcao> 
            <img src={logo} />
            <Opcao 
                color={'transparent'} 
                text={false}
                onClick={()=>{navigate('/escolher-tabela')}}  >
                <ion-icon name="cellular-outline"></ion-icon>
            </Opcao>  
            {/*<Opcao 
                color={pag==2?'var(--branco1)':'var(--branco2)'}
                onClick={()=>{setApostando(!apostando);setarPagina(2)}} >
                <ion-icon name="receipt-outline"></ion-icon>
            </Opcao>  
            <Opcao  
                color={pag==3?'var(--branco1)':'var(--branco2)'}
                onClick={()=>{setMenuando(!menuando);setarPagina(3)}}  >
                
            <ion-icon name="trophy-outline"></ion-icon>
            </Opcao>
            <Opcao  
                color={pag==4?'var(--branco1)':'var(--branco2)'}
                onClick={()=>{setProcurando(!procurando);setarPagina(4)}}  >
            <ion-icon name="search-outline"></ion-icon>
            </Opcao>*/}
        </Tudo>
    )
}
const Tudo=styled.div`
justify-content:space-between;
max-width:400px;
width:100vw;
height:64px;
background-color:var(--branco2);
img{height:38px;width:auto}
`
const Opcao=styled.div`cursor:pointer;z-index:3;
padding-bottom:2px;font-size:42px;
img{height:38px;width:auto}
    background-color:${props=>props.color};
    position:relative;
display:flex;align-items:center;
    justify-content:center;
    border:0;height:62px;width:25%;
    color:#008037;
h1{margin-left: auto;margin-right: auto;;width:50px;font-size:16px;
    small{font-size:14px;}
};
aside{display:none}

:hover{
    aside{display:block;
        position:absolute;;border-radius:5px;
        padding:5px;background-color:black;
        font-size:11px;color:white;
        position:absolute;z-index:1;bottom:0;left:center;
    }
}
:active{
    box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.2);
    }
`