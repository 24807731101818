import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import React from 'react';
import DarkContext from "../gerais/DarkContext";
import Loader from "./Loader";
import { useNavigate, useParams } from "react-router-dom";
import { getPartida, getPartidas } from "../api";
import Escudo from "./Escudo";
import { ligas } from "../ligas/contexts";
export default function Jogo(){
    const {setAnalise,a_partidas,bandeira,
        setNumeroGols,setNumeroEsc,setEscoAnalise,setBandeira}=useContext(DarkContext)
    const nome=a_partidas
    const navigate=useNavigate()
    useEffect(()=>{if(!nome)navigate('/')},[])
        const lis=nome?nome.split('-'):['','','']
    const [camp,manda,visi]=lis
    const paths=ligas.paths
    const context=ligas.contexts[paths.indexOf(bandeira)]
    const {listaNomes,listaTimes}=context
    const [partida,setPartida]=useState(false)
    function buscarJogo(){
        setBandeira(camp)
        setPartida(false)
        const promise=getPartida(camp,manda+visi)
        promise.then(res=>{
            setPartida(res.data)
        })
        
    }
    
    function irProPosAnalise(info,valor){
        const grandeza=parseInt(info[0])
        const c=parseInt(info[1])
        const asc=parseInt(info[2])
        const metade=parseInt(info[3])
        setAnalise({grandeza,c,asc,metade})
        if(grandeza==2)setNumeroGols(valor)
        if(grandeza==6)setNumeroEsc(valor)
        setEscoAnalise(false)
        navigate(`/pos-analise/${camp}-${manda}-${visi}`)
    }
    useEffect(buscarJogo,[])
    return(
        partida?<Tudo>
           <span>
                <Escudo camp={camp} time={manda}/>
                <div>{partida?.placar[0]}<h6>{partida?<ion-icon name="close-outline"></ion-icon>:''}</h6>  {partida?.placar[1]}</div>
                <Escudo camp={camp} time={visi}/>
            </span>
            <HolderNomes>
                    <h2>{listaNomes[listaTimes.indexOf(manda)]} </h2>
                    <h2>{listaNomes[listaTimes.indexOf(visi)]} </h2>
            </HolderNomes>
            
            <Apostas>
                <HolderJogo>
                    <Linha>
                        {partida.gols.map((gol,index)=>{
                            let afasta=0
                            if(index-1>=0){
                                const golAnt=partida.gols[index-1]
                                if(gol.minuto-golAnt.minuto<=3&&gol.mandante==golAnt.mandante)afasta=1
                            }
                            if(index-2>=0){
                                const golAntee=partida.gols[index-2]
                                if(gol.minuto-golAntee.minuto<=3&&gol.mandante==golAntee.mandante){
                                    if(afasta){afasta=2}else{afasta=1}
                                }
                            }
                            return(gol.mandante?
                            <GolM altura={gol.minuto*94/90} afasta={5+50*afasta}>
                                <h3>{gol.minuto}'</h3>
                                <ion-icon name="football"></ion-icon>
                            </GolM>
                            :
                            <GolV altura={gol.minuto*94/90} afasta={5+50*afasta}>
                                <ion-icon name="football"></ion-icon>
                                <h3>{gol.minuto}'</h3>
                            </GolV>
                        )})}
                    </Linha>
                    <Escant mandante={true}>
                        <p>{partida?partida.escant[0]:''}</p>
                        <ion-icon name="golf-outline"></ion-icon>
                    </Escant>
                    <Escant mandante={false}>
                        <p>{partida?partida.escant[1]:''}</p>
                        <ion-icon name="golf-outline"></ion-icon>
                    </Escant>
                </HolderJogo>
                {partida.apostas.length>0?<ShowerOdds>
                    Odds
                    <ion-icon name="arrow-down-outline"></ion-icon>
                </ShowerOdds>:<></>}
                {partida.apostas.map((item)=>{
                    const {info,texto,green,odd}=item
                    return(
                    <Holder solo={info[0]==1||odd.length==1}>
                        {info[0]!=1&&odd.length>1?<Texto>
                        {texto}
                        </Texto>:<></>}
                        {info[0]==2||info[0]==6?
                            odd.length>1?
                                odd.map(ap=>(
                                <Aposta  cor={ap.green==100?'#94EFAF':'#F4B5B5'} >
                                    <h5>{ap.t}</h5>
                                    <h6>{ap.o}</h6>
                                </Aposta>
                                ))
                                :
                                <Aposta cor={odd[0].green==100?'#94EFAF':'#F4B5B5'} folga={true} >
                                    <h5>{texto.replace('X',odd[0].q)}</h5>
                                    <h6>{odd[0].o}</h6>
                                </Aposta>
                        :<Aposta  cor={green==100?'#94EFAF':'#F4B5B5'} folga={true} >
                        <h5>{texto}</h5>
                        <h6>{odd}</h6>
                        </Aposta>
                        }
                    </Holder>
                )})}
                <MargemBaixo/>

            </Apostas>
        </Tudo>:
        <Loader/>
        
    )
}
const MargemBaixo=styled.div`
min-height:40px;width:50px;background-color:;
`
const ShowerOdds=styled.div`background-color:;
min-height:60px;width:100%;justify-content:;
box-sizing:border-box;
flex-direction:column;color:#6193DD;
`
const HolderJogo=styled.div`
width:100%;justify-content:center;
position:relative;height:100%;
padding-left:6px;
`
const Texto=styled.div`
color:white;
height:30px;
margin-top:10px;
font-size:13px
`
const Aposta=styled.div`
position:relative;
box-sizing:border-box;
padding:0 15px 0 15px;
justify-content:space-between;
background-color:${props=>props.cor};
height:40px;width:96%;
margin-top:${props=>props.folga?'15px':'5px'};
border-radius:20px;
color:black;
h6{font-weight:600;font-size:18px}
h5{font-size:13px;}
`

const Holder=styled.div`background-color:${props=>props.solo?'':'#6193DD'};
flex-wrap:wrap;position:relative;
width:90%;
border-radius:20px;
padding:0px 0 10px 0;
margin:${props=>props.solo?'0 0 0 12px':'10px 0 10px 12px'};
justify-content:space-around;
`
const Apostas=styled.div`
height:calc(100% - 125px);
width:98%;
margin-top:30px;
flex-direction:column;
overflow:hidden;
    overflow-y:scroll;
    ::-webkit-scrollbar {
        width:12px
      }
      ::-webkit-scrollbar-track {
        border-top-right-radius:5px;
        border-bottom-right-radius:5px;
      }
      ::-webkit-scrollbar-thumb {
        background: #e5e5e5; 
        border-radius:5px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #cecece; 
      }
`
const HolderNomes=styled.div`
margin:10px 0 0px 0;height:15px;
justify-content:center;width:92%;
h2{width:180px;color:#545454;}
`
const Escant=styled.div`
width:40px;height:80px;
border-radius:5px;
flex-direction:column;
justify-content:space-evenly;
position:absolute;
bottom:10px;
${props=>props.mandante?'left:30px':'right:18px'};
font-size:25px;color:white;
ion-icon{font-size:20px;color:var(--verde1)}
p{color:var(--verde1);font-weight:600;font-size:35}

`

const GolM=styled.div`
img{margin-left:5px;}
position:absolute;
width:50px;justify-content:flex-end;
bottom:${props=>props.altura}%;right:${props=>props.afasta}px;
`
const GolV=styled.div`
img{margin-right:5px;}
position:absolute;width:55px;
bottom:${props=>props.altura}%;
left:${props=>props.afasta}px;
`

const Linha=styled.div`
h3{font-size:20px}
font-size:25px;
position:relative;
min-height:calc(80vh - 135px);
width:3px;
background-color:var(--verde1);
margin:5px 0 20px 0;
`
const Tudo=styled.div`
span{div{width:60px;justify-content:center;
    margin:0 15px 10px 15px}
    height:70px;
    display:flex;align-items:flex-end;
    font-weight:500;font-size:35px;
    img{height:60px;margin:0px 15px 0 15px;cursor:pointer;}
    h6{font-weight:600;font-size:20px;margin:15px 10px 10px 10px;color:#545454;}
    ion-icon{color#545454;}
};
width:100%;
height:100%;
background-color:white;
flex-direction:column;
`
