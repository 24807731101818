import { useContext, useEffect } from "react";
import styled from "styled-components";
import DarkContext from "../gerais/DarkContext";
import { useNavigate } from "react-router-dom";
import { ligas } from "../ligas/contexts";
export default function EscolherTabela({onMenu}){
    const {keys,setKeys,setBandeirando,setMenuando,
        setPrimeiraVez,bandeira,
        seta_tabela
      }=useContext(DarkContext)
      const navigate=useNavigate()
      const {paths,imagens,nomes}=ligas
      console.log(paths,nomes,bandeira)
    useEffect(()=>{
        setKeys({...keys,partida:0})
    },[])
    return(
            <Tudo>
                <EscolherLiga onClick={()=>setBandeirando(true)}>
                        
                        <ion-icon name="repeat-outline"></ion-icon>
                        <img src={imagens[paths.indexOf(bandeira)]}/>
                        {nomes[paths.indexOf(bandeira)]}
                </EscolherLiga>
               
                <Escolha width={'90%'} handleClick={()=>{navigate(`/escolher-time`)}} nome='Estatísticas dos times' icone='shield-outline' />
                <Escolha width={'90%'} handleClick={()=>{navigate(`/classificacao`)}} nome='Classificação' icone='medal-outline' />
                    <Titulo>
                        <p>TABELAS</p>
                    </Titulo>
                <Escolha width={'90%'} handleClick={()=>{navigate(`/tabelas`);seta_tabela(1)}} nome='Placar' icone='calendar-clear-outline' />
                <Escolha width={'90%'} handleClick={()=>{navigate(`/tabelas`);seta_tabela(2)}} nome='Gols' icone='football-outline' />
                <Escolha width={'90%'} handleClick={()=>{navigate(`/tabelas`);seta_tabela(6)}} nome='Escanteios' icone='golf-outline' />
                    <aside>

                   
                <Escolha width={'calc(50% - 5px)'} handleClick={()=>{navigate(`/tabelas`);seta_tabela(7)}} nome='Primeiro a marcar' icone='football-outline' />
                <Escolha width={'calc(50% - 5px)'} handleClick={()=>{navigate(`/tabelas`);seta_tabela(8)}} nome='Último a marcar' icone='football-outline' />
                </aside>
            </Tudo>
    )
}//time?'times':clas?'classificacao'
function Escolha({handleClick,nome,icone,width,height}){
    const {setPrimeiraVez,
        bandeira,setMenuando,seta_tabela
      }=useContext(DarkContext)
      const navigate=useNavigate()
    return(
        <Esc onClick={handleClick}
         width={width}>
            <ion-icon name={icone}></ion-icon>
            <p>{nome}</p>
        </Esc>
    )
}
const Titulo=styled.div`
p{
color:#545454;
font-weight:700;font-size:15px;
};
padding:10px;width:90%;
border-radius:7px;
margin:10px 0 7px 0;
`
const Tudo=styled.div`
max-width:370px;
border-radius:20px;
h1{margin-top:50px;}
background-color:white;
box-sizing:border-box;
padding:5px 0 5px 0px;
width:94%;flex-direction:column;
justify-content:space-evenly;
position:fixed;${props=>props.onMenu?'top:200px':''};
aside{
    width:90%;display:flex;
    flex-direction:row;
    align-items:center;justify-content:space-between;
}
`
const Esc=styled.button`
cursor:pointer;
padding:10px;
background-color:#026d2e;
border-radius:7px;
margin-bottom:10px;
height:50px;
justify-content:space-evenly;
width:${props=>props.width};
font-size:14px;font-weight:500;background-color:#e8e8e8;
ion-icon{font-size:28px;margin:5px};
img{height:80%;width:auto;border-radius:20px;}
p{width:calc(100% - 50px)};
color:#545454;

`
const EscolherLiga=styled.div`cursor:pointer;
border-radius:15px;
width:90%;
height:50px;
margin:5px 0 10px 0;
justify-content:flex-start;
font-size:16px;font-weight:500;background-color:white;
ion-icon{font-size:28px;};
img{height:80%;width:auto;margin:0 7px 0 3px}
color:#545454;
`

